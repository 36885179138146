import React from 'react'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'


const ApiSignupModal = ({ show, handleClose }: any) => (
  <Modal show={show} onHide={handleClose} centered animation={false} >
        <Modal.Header closeButton >
        </Modal.Header>
        <Modal.Body>
          
        <Form action="https://mailthis.to/team@disastercheckin.app" method="POST" encType="multipart/form-data" >
        <Form.Row>
        <Col>
        <Form.Group controlId="firstName">
            <Form.Label>First name</Form.Label>
            <Form.Control type="text" />
          </Form.Group>
          </Col>
          <Col>
          <Form.Group controlId="lastName">
            <Form.Label>Last name</Form.Label>
            <Form.Control type="text"/>
          </Form.Group>
          </Col>
          </Form.Row>

          <Form.Row>
        <Col>
          <Form.Group controlId="email">
            <Form.Label>Email address*</Form.Label>
            <Form.Control required type="email"/>
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          </Col>
          <Col>
          <Form.Group controlId="organisation">
            <Form.Label>Organisation</Form.Label>
            <Form.Control type="text" />
          </Form.Group>
          </Col>
          </Form.Row>

          <Form.Row>
        <Col>
          <Form.Group controlId="jobTitle">
            <Form.Label>Job title / Role</Form.Label>
            <Form.Control type="text" />
          </Form.Group>
          </Col>
          <Col>
          <Form.Group controlId="phone">
            <Form.Label>Phone</Form.Label>
            <Form.Control type="tel" />
          </Form.Group>
          </Col>
          </Form.Row>
          <input type="hidden" name="_after" value="https://disastercheckin.app#success" />
          <Form.Group controlId="useCase">
          <Form.Label >
              Tell us about your use case, provide as much detail as possible*
            </Form.Label>
            <Form.Control as="textarea" />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>

        </Modal.Body>
      </Modal>
)

export default ApiSignupModal
