import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import ApiSignupModal from './ApiSignupModal';
// @ts-ignore
import {ReactComponent as AppStore} from './asset/img/appstore.svg';
import iphoneX from './asset/img/iphoneX.png';
import samwiseapp from './asset/img/samwiseapp.png';


function Home(_: { name: string }) {

  const [success, setSuccess] = useState(false);
  if (window.location.hash === '#success') {
    setSuccess(true);
    setTimeout(() => setSuccess(false), 5000);
    window.location.hash = '';
  }
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  return (
    <div>
    <a id="home"></a>
    <ApiSignupModal show={show} handleClose={handleClose} />
    <div className="App">
    <h5 id="banner-text" style={{ position: 'fixed', zIndex: 10000, padding: '10px', top: 0, textAlign: 'center', backgroundColor: 'white', color: '#37434C', width: '100%', fontSize: '1rem' }}>Disaster Check In has recently launched, we are working hard to improve our service. Please do not rely solely on our products for disaster updates, but as a useful aid. Stay safe.</h5>
      {success && <Alert variant="success">Success! we will be in contact shortly</Alert>}
      <section className="section-one" style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative' }}>

      <div style={{ width: '100%', position: 'absolute', top: 50, zIndex: 2 }}>
        <a href="https://apps.apple.com/gb/app/disastercheckin/id1507648140"><h5 className="header-links">Try now</h5></a>
        <a href="mailto:team@disastercheckin.app" target="_blank" rel="noopener noreferrer" ><h5 className="header-links">Contact</h5></a>
        <a href="https://disastersafe.co"><h5 className="header-links">API</h5></a>
      </div>
    <div
    id="bg-round"
    className="background-animation"
      style={{
        position: 'absolute',
        zIndex: 1,
        width: '1750px',
        height: '1750px',
        top: '-1100px',
        transform: 'translateX(-4%)',
        background: 'rgba(255,255,255, 0.1)',
        borderRadius: '100%',
        overflow: 'hidden',
      }}
    />        <div id="title-holder" style={{ textAlign: 'left', display: 'flex', justifyContent: 'start', marginTop: '20%' }}>

          <div style={{ marginTop: -50 }}>
          <h1 id="title">
            Disaster Check In 
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"  id="top-logo" fill="white" style={{opacity: 1}as any}><g data-name="Layer 2"><g data-name="pin"><rect width="24" height="24" opacity="0"></rect><path d="M12 2a8 8 0 0 0-8 7.92c0 5.48 7.05 11.58 7.35 11.84a1 1 0 0 0 1.3 0C13 21.5 20 15.4 20 9.92A8 8 0 0 0 12 2zm0 17.65c-1.67-1.59-6-6-6-9.73a6 6 0 0 1 12 0c0 3.7-4.33 8.14-6 9.73z"></path><path d="M12 6a3.5 3.5 0 1 0 3.5 3.5A3.5 3.5 0 0 0 12 6zm0 5a1.5 1.5 0 1 1 1.5-1.5A1.5 1.5 0 0 1 12 11z"></path></g></g></svg>
            {/* <i data-eva="pin-outline" data-eva-fill="white" data-eva-height="130" data-eva-width="130"></i> */}
          </h1>
          <div id="subtitle" >
            Get notified about ongoing disasters as they occur
            <br/>
            Check in with family and friends
          </div>
          <a id="testflight-link" href="https://apps.apple.com/gb/app/disastercheckin/id1507648140">
          <AppStore height={100} width={150} />
          </a>
          </div>
          <div></div>
          <div
          id="phone-background"
          style={{
            height: 400,
            width: 400,
            borderRadius: 400,
            fontWeight: 600,
            marginTop: 400,
            right: 0,
            top: 0,
            transform: 'translate(-25%, -50%)',
            position: 'absolute',
            zIndex: 3
          }}
        >
          <img src={iphoneX} className="iphone-bg" style={{ height: 500, marginTop: -50 }} />
          <img src={samwiseapp} className="iphone-fg" style={{ 
            height: '460px',
            width: '213px',
            position: 'absolute',
            marginLeft: '-271px',
            zIndex: -1,
            marginTop: '-36px'
          }} />
        </div>
        </div>
      </section>

      <section className="section-two">
        <div className="row" style={{ position: 'relative' }}>
        <div
          id="phone-background-2"
          style={{
            height: 400,
            // width: 400,
            marginTop: 100,
            marginBottom: 100,
            borderRadius: 400,
            fontWeight: 600,
            width: '100%',
            textAlign: 'center',
            zIndex: 3,
            transform:'translateX(-15px)'
          }}
        >
          <img src={iphoneX}  style={{ height: 500 }} />
          <img src={samwiseapp} id="app-screen-2" />
        </div>
          
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="section-two-logo">
            <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 24 24" fill="#3366FF"><g data-name="Layer 2"><g data-name="shake"><rect width="24" height="24" opacity="0"></rect><path d="M5.5 18a1 1 0 0 1-.64-.24A8.81 8.81 0 0 1 1.5 11a8.81 8.81 0 0 1 3.36-6.76 1 1 0 1 1 1.28 1.52A6.9 6.9 0 0 0 3.5 11a6.9 6.9 0 0 0 2.64 5.24 1 1 0 0 1 .13 1.4 1 1 0 0 1-.77.36z"></path><path d="M12 7a4.09 4.09 0 0 1 1 .14V3a1 1 0 0 0-2 0v4.14A4.09 4.09 0 0 1 12 7z"></path><path d="M12 15a4.09 4.09 0 0 1-1-.14V20a1 1 0 0 0 2 0v-5.14a4.09 4.09 0 0 1-1 .14z"></path><path d="M16 16a1 1 0 0 1-.77-.36 1 1 0 0 1 .13-1.4A4.28 4.28 0 0 0 17 11a4.28 4.28 0 0 0-1.64-3.24 1 1 0 1 1 1.28-1.52A6.2 6.2 0 0 1 19 11a6.2 6.2 0 0 1-2.36 4.76A1 1 0 0 1 16 16z"></path><path d="M8 16a1 1 0 0 1-.64-.24A6.2 6.2 0 0 1 5 11a6.2 6.2 0 0 1 2.36-4.76 1 1 0 1 1 1.28 1.52A4.28 4.28 0 0 0 7 11a4.28 4.28 0 0 0 1.64 3.24 1 1 0 0 1 .13 1.4A1 1 0 0 1 8 16z"></path><path d="M18.5 18a1 1 0 0 1-.77-.36 1 1 0 0 1 .13-1.4A6.9 6.9 0 0 0 20.5 11a6.9 6.9 0 0 0-2.64-5.24 1 1 0 1 1 1.28-1.52A8.81 8.81 0 0 1 22.5 11a8.81 8.81 0 0 1-3.36 6.76 1 1 0 0 1-.64.24z"></path><path d="M12 12a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm0-1zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0z"></path></g></g></svg>
            </div>
          <h4>
            Stay ahead of disasters
          </h4>
          <p>
            Disaster Check In plugs into reliable and up to date data sources to keep you ahead of any natural or man made disasters near you. 
          </p>
        </div>
        <div className="col-md-4">
          <div className="section-two-logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 24 24" fill="#3366FF"><g data-name="Layer 2"><g data-name="alert-circle"><rect width="24" height="24" opacity="0"></rect><path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"></path><circle cx="12" cy="16" r="1"></circle><path d="M12 7a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0V8a1 1 0 0 0-1-1z"></path></g></g></svg>
          </div>
          <h4>
          Get alerted
          </h4>
          <p>
          Receive push notifications, SMS and email (coming soon) to make sure you don't miss critical alerts. 
          </p>
        </div>
        <div className="col-md-4">
          <div className="section-two-logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 24 24" fill="#3366FF"><g data-name="Layer 2"><g data-name="people"><rect width="24" height="24" opacity="0"></rect><path d="M9 11a4 4 0 1 0-4-4 4 4 0 0 0 4 4zm0-6a2 2 0 1 1-2 2 2 2 0 0 1 2-2z"></path><path d="M17 13a3 3 0 1 0-3-3 3 3 0 0 0 3 3zm0-4a1 1 0 1 1-1 1 1 1 0 0 1 1-1z"></path><path d="M17 14a5 5 0 0 0-3.06 1.05A7 7 0 0 0 2 20a1 1 0 0 0 2 0 5 5 0 0 1 10 0 1 1 0 0 0 2 0 6.9 6.9 0 0 0-.86-3.35A3 3 0 0 1 20 19a1 1 0 0 0 2 0 5 5 0 0 0-5-5z"></path></g></g></svg>
          </div>
          <h4>
          Check in with family and friends
          </h4>
          <p>
          Use Disaster Check In to check in with loved ones and let them know you are safe. 
          </p>
        </div>
      </div>
    </section>

      <section className="section-three">
        <div className="row">
          <div className="col-md-6">
          <h1 id="mission">Our mission</h1>
          <hr id="mission-underline" />
          <h5 id="mission-statement">
            To give every person across the world access to early warning data for natural disasters.
          </h5>
          <div style={{ textAlign: 'left', marginLeft: 65, marginTop: 50 }}>
          {/* <button
          style={{
            backgroundColor:'#6772E5',
            color:'#FFF',
            padding: '8px 12px',
            border: 0,
            borderRadius: '4px',
            fontSize:'1.2em'
          }}
          id="checkout-button-sku_H8KvwHkOxiG4eY"
          role="link"
          onClick={() => {
            const stripe = window.Stripe('pk_live_88Bo8zyBkQYMElnVyHj5NwX500FP0TKz27')
            stripe.redirectToCheckout({
              items: [{sku: 'sku_H8KvwHkOxiG4eY', quantity: 1}],
              successUrl: 'https://disastercheckin.app',
              cancelUrl: 'https://disastercheckin.app'
            })
            .then((result) => {
              if (result.error) {
                setStripeError(result.error.message)
              }
            })
          }}
        >
          Support us
        </button> */}
        </div>
          </div>
          <div className="col-md-6" style={{ position: 'relative' }}>
            <div style={{ backgroundColor: 'rgba(205,205,255,0.2)', position: 'absolute', height: '100%', width: '100%' }}></div>
            <img src={'https://images.unsplash.com/photo-1572989994359-ae5afc3a3efe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'} width="100%" style={{ maxHeight: 600, objectFit: 'cover' }} />
          </div>
        </div>
      </section>

    </div>
    </div>
  );
}

export default Home;
