import React from 'react';
import './App.css';
import Home from './Home'
import Footer from './Footer'
import PrivacyPolicy from './PrivacyPolicy'
import TermsService from './TermsService'

import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
const name = 'Disaster Check In'

const App = () => (
  <Router>
    <Switch>
    <Route path="/privacy" component={PrivacyPolicy} />
    <Route path="/terms" component={TermsService} />
    <Route path="/current-disasters" component={() =><div>Test</div>} />
    <Route path="*" component={() => <Home name={name} />} />
    </Switch>
    <Footer name={name}/>
  </Router>
)

export default App;
