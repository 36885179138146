import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const Footer = ({ name }: { name: string }) => (
  <footer>
  <div className="row">
    {/* <hr style={{}}/> */}
    <div className="col-6">
      <h6>United Kingdom</h6>
      <h6 style={{ marginTop: 20 }}>England</h6>
      <h6 style={{ marginTop: 200 }}>© {name} {new Date().getFullYear()}</h6>
      <h6>Rabbit Island LTD</h6>
    </div>
    
    <div className="col-6" style={{ textAlign: 'right' }}>
    <Link to="/#home" ><h6 style={{ textAlign: 'right' }}>Home</h6></Link>
    <a href="https://disastersafe.co" ><h6 style={{ textAlign: 'right', marginTop: 20 }}>API</h6></a>
    <a href="mailto:team@disastercheckin.app" target="_blank" rel="noopener noreferrer" ><h6 style={{ textAlign: 'right', marginTop: 20  }}>Contact</h6></a>
    <Link to="/terms#top" ><h6 style={{ marginTop: 20 }}>Terms of Service</h6></Link>
    <Link to="/privacy#top"><h6 style={{ marginTop: 20 }}>Privacy Policy</h6></Link>
    </div>
  </div>
  </footer>
)

export default Footer